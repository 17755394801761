import PropTypes from 'prop-types';

import styles from './styles.module.scss';

export default function Squarify(props) {
  const { children, className, disable = false, ...otherProps } = props;

  let classNames = disable ? '' : styles.squarify;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {children}
    </div>
  );
}

Squarify.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disable: PropTypes.bool,
};
