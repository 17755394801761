import PropTypes from 'prop-types';
import { forwardRef, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

/**
 * Layout component which renders a skewed background.
 *
 * @param {boolean} [props.animateBackground=false] Animate the background elements.
 * @param {number} [props.animationDelayMs=0] Set a delay for the background animation (in milliseconds).
 * @param {string} [props.backgroundClassName] The class for the background element. Target this to set the
 * background color or image.
 * @param {Node} [props.backgroundContent] Content to render inside the background element.
 * @param {string} [props.className] The class for the container element. Use `backgroundClassName` if you want to
 * set the background color or image.
 * @param {string} [props.contentClassName] The class for the content container element.
 * @param {string} [props.waveBackBackgroundColor]
 * @param {string} [props.waveFrontBackgroundColor]
 */
const SkewedBackgroundLayout = forwardRef((props, forwardedRef) => {
  /* Variables */

  const {
    animateBackground = false,
    animationDelayMs = 0,
    backgroundClassName,
    backgroundContent,
    children,
    className,
    contentClassName,
    waveBackBackgroundColor,
    waveFrontBackgroundColor,
    ...otherProps
  } = props;

  const [backgroundOffset, setBackgroundOffset] = useState(0);
  const innerRef = useRef();
  const ref = forwardedRef || innerRef;

  /* Effects */

  /**
   * Background-centering effect.
   *
   * The background should be centered vertically behind the content. To achieve this we multiply half of the width of
   * the layout element by tan(θ) (value stored at the bottom of this file). This gives us the distance to shift the
   * background up and extend it below to be vertically centered.
   *
   * This formula was derived by considering the skewed background as if it were drawn centered behind the content box.
   * The portions of the skewed background above and below the content box are triangles intersecting at the center of
   * the content box's top edge. Since we know the width of the content box, each triangle's base can be calculated as
   * half of the content box's width. And since we know the angle the background is skewed at, we know enough to
   * calculate the missing value. We can use the formula tan(θ) = opposite / adjacent. In this case, the side adjacent
   * to the angle is half of the layout element's width, and the opposite side is the distance the background element
   * needs to be shifted up and extended below.
   *
   * This is necessary because the skewed mask element is skewed at the horizontal centerpoint. The background element
   * within will then be rendered from the centerpoint, which results in a gap between the top of the mask and the top
   * of the background element. The calculated distance is therefore used to:
   *   a) shift the background element up
   *   b) increase the background's height by double this amount (accounting for top and bottom)
   *   c) pad the content vertically (accounting for equal and inverse "triangles" of the content box not covered by)
   *      the background.
   */
  useEffect(() => {
    const layoutWidth = ref.current.clientWidth;
    setBackgroundOffset((layoutWidth / 2) * tan3Deg);
  }, [ref]);

  /* Render */

  // Setup classes

  let classNames = styles.container;
  if (className) {
    classNames = `${classNames} ${className}`;
  }

  let backgroundClassNames = styles.background;
  if (backgroundClassName) {
    backgroundClassNames = `${backgroundClassNames} ${backgroundClassName}`;
  }

  let contentClassNames = styles.content;
  if (contentClassName) {
    contentClassNames = `${contentClassNames} ${contentClassName}`;
  }

  // Instance styles

  // Custom styles to center the background behind the content (see explanation above).
  const backgroundStyles = {
    height: `calc(100% + (${backgroundOffset}px * 2))`,
    top: -backgroundOffset,
  };
  const backgroundContentStyles = {
    paddingBottom: backgroundOffset,
    paddingTop: backgroundOffset,
  };

  // Setup waves
  let waveBackStyles = {};
  let waveFrontStyles = {};
  if (animateBackground) {
    waveBackStyles.animationDelay = `${animationDelayMs + 2000}ms`;
    waveBackStyles.animationPlayState = 'running';
    waveFrontStyles.animationDelay = `${animationDelayMs}ms`;
    waveFrontStyles.animationPlayState = 'running';
  }
  if (waveBackBackgroundColor) {
    waveBackStyles.backgroundColor = waveBackBackgroundColor;
  }
  if (waveFrontBackgroundColor) {
    waveFrontStyles.backgroundColor = waveFrontBackgroundColor;
  }

  return (
    <>
      <div className={classNames} ref={ref} {...otherProps}>
        <div className={styles.waveBack} style={waveBackStyles} />
        <div className={styles.waveFront} style={waveFrontStyles} />
        <div className={styles.skewedMask}>
          <div className={backgroundClassNames} style={backgroundStyles}>
            {backgroundContent}
          </div>
        </div>
        <div className={contentClassNames} style={backgroundContentStyles}>
          {children}
        </div>
      </div>
    </>
  );
});

SkewedBackgroundLayout.propTypes = {
  animateBackground: PropTypes.bool,
  animationDelayMs: PropTypes.number,
  backgroundClassName: PropTypes.string,
  backgroundContent: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  waveBackBackgroundColor: PropTypes.string,
  waveFrontBackgroundColor: PropTypes.string,
};

export default SkewedBackgroundLayout;

/* Helper variables */

/**
 * The stored value of tan(θ) where θ = 3 degrees. We use this value to calculate the distance to shift the background
 * so it is centered behind the content. Detailed explanation in the component's effect which is responsible for
 * calculating the distance.
 *
 * If the angle at which the background is skewed changes, this variable will need to be updated.
 */
const tan3Deg = 0.052407779;
