import { useQuery } from '@apollo/client';
import isFuture from 'date-fns/isFuture';
import NextImage from 'next/legacy/image';
import { NextSeo } from 'next-seo';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PotentialEarningsSection from './potentialEarningsSection';
import styles from './styles.module.scss';

import ArticleCard from 'OK/components/article/card';
import Button from 'OK/components/button';
import { Slide, Carousel } from 'OK/components/carousel/old';
import EventCard, { EventType } from 'OK/components/event/card';
import FooterFineprint from 'OK/components/footer/fineprint';
import { Footnote, Footnotes } from 'OK/components/footnotes';
import Icon, { ICONS } from 'OK/components/icon';
import Image from 'OK/components/image';
import ContentLayout from 'OK/components/layouts/content';
import TextContent from 'OK/components/layouts/content/text';
import MainLayout from 'OK/components/layouts/main';
import SkewedBackgroundLayout from 'OK/components/layouts/skewedBackground';
import Link, { LinkNoRedux } from 'OK/components/link';
import MarketingPDFCard from 'OK/components/marketingPDF/card';
import Menu from 'OK/components/menu';
import PageMenu from 'OK/components/menus/page';
import Notice from 'OK/components/notice';
import Progressable from 'OK/components/progressable';
import Separator from 'OK/components/separator';
import Squarify from 'OK/components/squarify';
import { BrandSubscription, EnterpriseSubscription, FreeSubscription } from 'OK/components/subscription';
import TestimonialCard from 'OK/components/testimonial/card';
import Text from 'OK/components/text';
import RollingTextCarousel from 'OK/components/text/rollingCarousel';
import Toggle from 'OK/components/toggle';
import FeaturesPopup from 'OK/modules/popups/features';
import VideoPlayerPopup from 'OK/modules/popups/vimeoVideoPlayer';
import { getLatestArticleQuery, getLatestWebinarQuery } from 'OK/networking/news';
import HowItWorksImage1 from 'OK/public/img/how_it_works_img_1@2x.jpg';
import HowItWorksImage2 from 'OK/public/img/how_it_works_img_2@2x.jpg';
import HowItWorksImage3 from 'OK/public/img/how_it_works_img_3@2x.jpg';
import { setComingFromTrial } from 'OK/state/account/actions';
import { showAuthModal } from 'OK/state/app/actions';
import { trackEvent } from 'OK/util/analytics';
import { CALENDLY_LINK } from 'OK/util/constants';
import ThemeContext from 'OK/util/context/theme';
import { formatDate } from 'OK/util/formatting';
import useAuthentication from 'OK/util/hooks/useAuthentication';
import useI18n from 'OK/util/hooks/useI18n';
import { addURLQueryParam } from 'OK/util/routing';

function Homepage() {
  /* Variables */

  const defaultContentWidth = useSelector((state) => state.app.defaultContentWidth);
  const dispatch = useDispatch();
  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const useMobileLayout = useSelector((state) => state.app.useMobileLayout);
  const theme = useContext(ThemeContext);
  const { t, tHTML, locale, i18nImageSrc } = useI18n();

  /* State */

  const [renderFeaturesPopup, setRenderFeaturesPopup] = useState(false);
  const [renderVideoPopup, setRenderVideoPopup] = useState(false);
  const [showAnnualPricing, setShowAnnualPricing] = useState(true);

  // Misc
  const [authenticated] = useAuthentication(() => false);

  /* API */

  const getLatestArticleResult = useQuery(getLatestArticleQuery);
  const latestArticle = getLatestArticleResult.data?.article;
  const getLatestWebinarResult = useQuery(getLatestWebinarQuery);
  const latestWebinar = getLatestWebinarResult.data?.webinar;

  // Refs
  const benefitSectionRef = useRef();
  const featuresSectionRef = useRef();
  const plansSectionRef = useRef();
  const pricingCarouselRef = useRef();
  const trustSectionRef = useRef();
  const videoPlayerRef = useRef();

  /* Methods */

  const closeVideo = useCallback(() => {
    setRenderVideoPopup(false);
  }, []);

  const openVideo = useCallback(() => {
    setRenderVideoPopup(true);
    videoPlayerRef.current.play();
  }, []);

  const scrollToTop = useCallback(() => window.scrollTo({ top: 0, behavior: 'smooth' }), []);

  const onClickTryForFree = useCallback(() => {
    dispatch(showAuthModal(true));
    dispatch(setComingFromTrial(true));
  }, [dispatch]);

  /* Effects */

  // Track homepage visit
  useEffect(() => {
    setTimeout(() => {
      trackEvent('Viewed homepage');
    }, 1000);
  }, []);

  // Show annual pricing plan by default on mobile
  useEffect(() => {
    if (useMobileLayout) {
      setTimeout(() => {
        pricingCarouselRef.current.scrollToSlideAtIndex(1, true);
      }, 1000);
    }
  }, [useMobileLayout]);

  /* Render */

  const appPreview = (
    <div className={styles.appPreviewContainer}>
      <picture>
        <source
          type='image/webp'
          srcSet={`${i18nImageSrc(`/img/landing_app_preview_${theme.name}.webp`)} 1x, ${i18nImageSrc(
            `/img/landing_app_preview_${theme.name}@2x.webp`
          )} 2x`}
        />
        <source
          type='image/png'
          srcSet={`${i18nImageSrc(`/img/landing_app_preview_${theme.name}.png`)} 1x, ${i18nImageSrc(
            `/img/landing_app_preview_${theme.name}@2x.png`
          )} 2x`}
        />
        <img
          alt={t('IMG_ALT_APP_PREVIEW')}
          src={i18nImageSrc(`/img/landing_app_preview_${theme.name}.png`)}
          srcSet={`${i18nImageSrc(`/img/landing_app_preview_${theme.name}.png`)} 1x, ${i18nImageSrc(
            `/img/landing_app_preview_${theme.name}@2x.png`
          )} 2x`}
        />
      </picture>
    </div>
  );
  const circularSupplyChainImage = (
    <Image
      addSrcSet
      alt='Circular supply chain'
      className={styles.circularSupplyChainGraphic}
      localized
      src={`circular_supply_chain_${useDesktopLayout ? 'desktop' : 'mobile'}.png`}
      themed
    />
  );
  const deskBananasImage = (
    <div className={styles.deskBananas}>
      <NextImage
        alt='Cute animals working at a desk covered in bananas.'
        height='710'
        src='/img/desk_bananas@2x.png'
        width='977'
      />
    </div>
  );
  const pageSections = [
    { ref: featuresSectionRef, title: t('HOMEPAGE_MENU_WHY_OK') },
    { ref: benefitSectionRef, title: t('HOMEPAGE_MENU_BENEFITS') },
    { ref: trustSectionRef, title: t('HOMEPAGE_MENU_SUPPORT') },
    { ref: plansSectionRef, title: t('HOMEPAGE_MENU_PLANS') },
  ];

  const homepageTitle = `OK - ${t('PAGE_TITLE_HOME')}`;

  return (
    <>
      <NextSeo
        title={homepageTitle}
        description={t('PAGE_DESCRIPTION_HOME')}
        openGraph={{
          type: 'website',
          url: 'https://www.oktrade.org',
          title: homepageTitle,
          description: t('PAGE_DESCRIPTION_HOME'),
          images: [
            {
              url: 'https://okprodeustorage.blob.core.windows.net/images/uwI6pK0r_1679984579090.jpg',
              width: 1400,
              height: 1400,
              alt: 'OK Logo',
            },
          ],
        }}
      />
      <Menu>
        <PageMenu
          hideWhenScrolledToTop
          sections={pageSections}
          smartTabs
          tabClassName={styles.pageMenuTab}
          themeWithBackgroundHidden='dark'
          title={
            <button className={styles.scrollToTopButton} onClick={scrollToTop}>
              <Icon className={styles.scrollToTopIcon} color={theme.colors.navigation} name={ICONS.CARET.name} />
              &nbsp;{t('HOMEPAGE_MENU_TOP')}
            </button>
          }
          verticalOffsetPx={theme.sizing.mainMenuHeight}
        />
      </Menu>
      <div className={styles.container}>
        <ContentLayout className={styles.heroSection} id='heroSection' pageContent>
          <div className={styles.heroBackground} />
          <div className={styles.landingContainer}>
            <TextContent className={styles.landingTextContainer}>
              <h1 className={styles.tagline}>
                {t('HOMEPAGE_SLOGAN_PART_1')}
                <RollingTextCarousel
                  words={[
                    t('HOMEPAGE_SLOGAN_KEYWORDS_DOCUMENTATION'),
                    t('HOMEPAGE_SLOGAN_KEYWORDS_WORKFLOWS'),
                    t('HOMEPAGE_SLOGAN_KEYWORDS_SOURCING'),
                    t('HOMEPAGE_SLOGAN_KEYWORDS_SUPPLY_CHAINS'),
                  ]}
                />
                {t('HOMEPAGE_SLOGAN_PART_2')}
              </h1>
              <Button block className={styles.heroButton} icon={ICONS.PLAY.name} onClick={openVideo} tint='navigation'>
                {t('HOMEPAGE_WATCH_INTRO_BUTTON')}
              </Button>
              <Button
                className={
                  !authenticated
                    ? `${styles.sectionButton} intercom`
                    : ` ${styles.sectionButtonAuth} ${styles.sectionButton} intercom `
                }
                icon={ICONS.MESSAGES.name}
                style={{ marginBottom: 10 }}
                tint='navigation'
              >
                {t('START_CONVERSATION_BUTTON')}
              </Button>
              {!authenticated && (
                <Button
                  block
                  className={styles.heroButton}
                  icon={ICONS.ACCESS.name}
                  tint='creation'
                  onClick={onClickTryForFree}
                >
                  {t('HOMEPAGE_TRY_FOR_FREE_BUTTON')}
                </Button>
              )}
              <Text className={styles.heroDescription}>{tHTML('HOMEPAGE_HERO_DESCRIPTION')}</Text>
            </TextContent>
          </div>
        </ContentLayout>
        <SkewedBackgroundLayout
          animateBackground
          className={styles.featuresSection}
          ref={featuresSectionRef}
          waveBackBackgroundColor='#8D8D8D'
          waveFrontBackgroundColor='#686868'
        >
          <ContentLayout pageContent>
            <div className={`${styles.appPreviewOuterContainer}`}>{appPreview}</div>
            <div className={`${styles.sectionIntro} ${styles.easySectionIntro}`}>
              <Text bold className={styles.sectionSubHeader} tint='notification'>
                {t('HOMEPAGE_WHY_OK_SECTION_SMALL_HEADER')}
              </Text>
              <h2>{tHTML('HOMEPAGE_WHY_OK_SECTION_HEADER')}</h2>
            </div>
            <div className={styles.easySection}>
              <div className={styles.easyItem}>
                <div className={styles.easyItemContent}>
                  <div className={styles.easyItemHover}>
                    <div className={styles.easyItemImg}>
                      <NextImage alt={t('IMG_ALT_EASY_SECTION_IMAGE_1')} placeholder='blur' src={HowItWorksImage1} />
                    </div>
                    <TextContent>
                      <h4 className={styles.easyItemHeader}>{t('HOMEPAGE_WHY_OK_SECTION_REASON_1')}</h4>
                      <p>{t('HOMEPAGE_WHY_OK_SECTION_REASON_DESCRIPTION_1')}</p>
                    </TextContent>
                  </div>
                </div>
              </div>
              <div className={styles.easyItem}>
                <div className={styles.easyItemContent}>
                  <div className={styles.easyItemHover}>
                    <div className={styles.easyItemImg}>
                      <NextImage alt={t('IMG_ALT_EASY_SECTION_IMAGE_2')} placeholder='blur' src={HowItWorksImage2} />
                    </div>
                    <TextContent>
                      <h4 className={styles.easyItemHeader}>{t('HOMEPAGE_WHY_OK_SECTION_REASON_2')}</h4>
                      <p>{t('HOMEPAGE_WHY_OK_SECTION_REASON_DESCRIPTION_2')}</p>
                    </TextContent>
                  </div>
                </div>
              </div>
              <div className={styles.easyItem}>
                <div className={styles.easyItemContent}>
                  <div className={styles.easyItemHover}>
                    <div className={styles.easyItemImg}>
                      <NextImage alt={t('IMG_ALT_EASY_SECTION_IMAGE_3')} placeholder='blur' src={HowItWorksImage3} />
                    </div>
                    <TextContent>
                      <h4 className={styles.easyItemHeader}>{t('HOMEPAGE_WHY_OK_SECTION_REASON_3')}</h4>
                      <p>{t('HOMEPAGE_WHY_OK_SECTION_REASON_DESCRIPTION_3')}</p>
                    </TextContent>
                  </div>
                </div>
              </div>
            </div>
            <TextContent>
              <Separator type='section' />
              <h3 style={{ marginBottom: 40 }}>{t('HOMEPAGE_JOIN_LEADING_BRANDS')}</h3>
            </TextContent>
            <div className={styles.testimonials}>
              <TestimonialCard
                authorName='Tommy'
                authorImageUrl='/img/testimonials/Tommy.png'
                className={styles.testimonialCard}
                logoImageUrl={`img/testimonials/hakko_bako_${theme.name}.svg`}
                organisationName='HakkoBako'
                quote={
                  <>
                    <Text>
                      In food-tech, consistency in ingredients, quality assurance, and workflows is everything.
                    </Text>
                    <Text>
                      Being able to easily set up (and follow up) work anywhere in our supply chain is a blessing.
                    </Text>
                  </>
                }
              />
              <TestimonialCard
                authorName='John'
                authorImageUrl='/img/testimonials/John.png'
                className={styles.testimonialCard}
                logoImageUrl={`img/testimonials/sino_etail_${theme.name}.svg`}
                organisationName='Sino Etail'
                quote={
                  <>
                    <Text>We manage multiple suppliers across Asia, which can be a time consuming endeavour.</Text>
                    <Text>
                      OK allows us to easily achieve remote oversight in our supply chain, giving us space to grow.
                    </Text>
                  </>
                }
              />
              <MarketingPDFCard className={styles.testimonialCard} />
            </div>
            <TextContent>
              <h3>{t('HOMEPAGE_AS_COVERED_BY')}</h3>
              <img
                alt='Covered by media'
                className={styles.mediaLogos}
                src={`/img/covered_by_media_${useDesktopLayout ? 'desktop' : 'mobile'}_${theme.name}.svg`}
              />
              <Text bold size='xs'>
                {t('HOMEPAGE_AND_OTHER_MEDIA_OUTLETS')}
              </Text>
            </TextContent>
          </ContentLayout>
        </SkewedBackgroundLayout>
        {/* <SkewedBackgroundLayout
          animateBackground
          animationDelayMs={2000}
          backgroundClassName={styles.worthwhileBackground}
          className={styles.worthwhileContainer}
          ref={benefitSectionRef}
          waveBackBackgroundColor='#272727'
          waveFrontBackgroundColor='#E2E2E2'
        > */}
        <div className={styles.worthwhileOuterContainer} ref={benefitSectionRef} id='benefits'>
          <ContentLayout className={`${styles.worthwhileContainer} ${styles.worthwhileBackground}`} pageContent>
            <div>
              {useDesktopLayout && circularSupplyChainImage}
              <TextContent className={styles.sectionIntro}>
                <Text bold className={styles.sectionSubHeader} tint='notification'>
                  {t('HOMEPAGE_BENEFITS_SECTION_SMALL_HEADER')}
                </Text>
                <h2 className={styles.sectionHeader}>{tHTML('HOMEPAGE_BENEFITS_SECTION_HEADER')}</h2>
                {useMobileLayout && circularSupplyChainImage}
                {tHTML('HOMEPAGE_BENEFITS_SECTION_DESCRIPTION')}
              </TextContent>
            </div>
          </ContentLayout>
          <div className={styles.worthwhileFade} />
        </div>
        <ContentLayout className={styles.takeAnOKStepContainer} pageContent>
          <TextContent>
            <h3 className={styles.sectionHeader}>{t('HOMEPAGE_TAKE_AN_OK_STEP')}</h3>
          </TextContent>
          <div className={styles.eventsContainer}>
            <div className={styles.eventCardContainer}>
              <Squarify className={styles.eventCardSquarify} disable={useDesktopLayout}>
                <EventCard
                  badge={
                    <>
                      {t('BOOK_A_DEMO')}{' '}
                      <Icon inline name={ICONS.EXTERNAL_LINK.name} style={{ height: 12, width: 12, marginLeft: 5 }} />
                    </>
                  }
                  className={styles.eventCard}
                  dateString={t('WEEKDAYS')}
                  eventType={EventType.DEMO}
                  href={CALENDLY_LINK}
                  imageUrl='/img/book_a_demo_img.jpg'
                  squareMedia={useDesktopLayout}
                  title={t('GET_A_PERSONAL_DEMO')}
                />
              </Squarify>
              <div className={styles.eventLink} style={{ display: useMobileLayout ? 'none' : 'block' }} />
            </div>
            <div className={styles.eventCardContainer}>
              <Squarify className={styles.eventCardSquarify} disable={useDesktopLayout}>
                <Progressable className={styles.eventCardLoadingContainer} inProgress={getLatestWebinarResult.loading}>
                  {latestWebinar ? (
                    <EventCard
                      badge={isFuture(latestWebinar.startDate) && t('REGISTER_NOW')}
                      className={styles.eventCard}
                      dateString={formatDate(latestWebinar.startDate, locale)}
                      eventType={EventType.WEBINAR}
                      imageUrl={latestWebinar.imageURL}
                      onClick={() => {
                        if (latestWebinar.id) {
                          addURLQueryParam('webinarId', latestWebinar.id, { scroll: false });
                        }
                      }}
                      squareMedia={useDesktopLayout}
                      title={latestWebinar.title}
                    />
                  ) : (
                    <TextContent>
                      <Text bold>{t('NO_UPCOMING_WEBINAR')}</Text>
                    </TextContent>
                  )}
                </Progressable>
              </Squarify>
              <TextContent>
                <Link block className={`${styles.eventLink} ${styles.sectionLink}`} href='/solutions/news' withCaret>
                  {t('MORE_WEBINARS')}
                </Link>
              </TextContent>
            </div>
            <div className={styles.eventCardContainer}>
              <Squarify
                className={styles.eventCardSquarify}
                disable={useDesktopLayout}
                style={{ height: 'calc(100% - 44px - 10px)' }}
              >
                <Progressable className={styles.eventCardLoadingContainer} inProgress={getLatestArticleResult.loading}>
                  {latestArticle ? (
                    <ArticleCard
                      addMediaSizer={useDesktopLayout}
                      authorImageUrl={latestArticle.articleWriterList[0]?.imageURL}
                      authorJobTitle={latestArticle.articleWriterList[0]?.jobTitle}
                      authorName={latestArticle.articleWriterList[0]?.name}
                      className={styles.eventCard}
                      fixedWidth={false}
                      href={latestArticle.articleURL}
                      imageUrl={latestArticle.imageURL}
                      readingTime={latestArticle.articleReadingTime}
                      title={latestArticle.title}
                    />
                  ) : (
                    <TextContent>
                      <Text bold>{t('NO_ARTICLE')}</Text>
                    </TextContent>
                  )}
                </Progressable>
              </Squarify>
              <TextContent>
                <Link block className={`${styles.eventLink} ${styles.sectionLink}`} href='/solutions/news' withCaret>
                  {t('MORE_ARTICLES')}
                </Link>
              </TextContent>
            </div>
          </div>
        </ContentLayout>
        {/* </SkewedBackgroundLayout> */}
        <SkewedBackgroundLayout
          animateBackground
          backgroundContent={
            <div className={styles.trustworthySectionLightbulb}>
              <NextImage height='230' src='/img/ceiling_bulb.png' width='128' />
            </div>
          }
          backgroundClassName={styles.trustworthyBackground}
          className={styles.trustworthySection}
          contentClassName={styles.trustworthyContent}
          ref={trustSectionRef}
          waveBackBackgroundColor='#E2E2E2'
          waveFrontBackgroundColor='#272727'
          id='support'
        >
          <ContentLayout pageContent>
            <div className={styles.trustworthySectionIntroContent}>
              <div>
                <TextContent className={`${styles.sectionIntro} ${styles.trustworthyIntroLeft}`}>
                  <Text bold className={styles.sectionSubHeader} tint='notification'>
                    {t('HOMEPAGE_SUPPORT_SECTION_SMALL_HEADER')}
                  </Text>
                  <h2 className={styles.sectionHeader}>{tHTML('HOMEPAGE_SUPPORT_SECTION_HEADER')}</h2>
                  <Text bold>{t('HOMEPAGE_SUPPORT_SECTION_LINE_1')}</Text>
                  <Text style={{ marginBottom: useDesktopLayout ? 30 : 0 }}>
                    {tHTML('HOMEPAGE_SUPPORT_SECTION_LINE_2', {
                      data: {
                        call: (
                          <LinkNoRedux href='/solutions/contact' style={{ margin: 0 }}>
                            {t('HOMEPAGE_SUPPORT_SECTION_LINE_2_CALL_WORD')}
                          </LinkNoRedux>
                        ),
                        messages: (
                          <Button
                            className={`${styles.inlineMessagesButton} intercom`}
                            icon={ICONS.MESSAGES.name}
                            iconPosition='left'
                            linkStyle
                          >
                            {t('MAIN_MENU_BUTTON_SUPPORT')}
                          </Button>
                        ),
                      },
                    })}
                  </Text>
                </TextContent>
                {useMobileLayout && deskBananasImage}
                <TextContent>
                  <Button
                    className={`${styles.sectionButton} intercom`}
                    icon={ICONS.MESSAGES.name}
                    style={{ marginBottom: 10 }}
                    tint='navigation'
                  >
                    {t('START_CONVERSATION_BUTTON')}
                  </Button>
                  <Link block className={styles.sectionLink} href='/solutions/contact' withCaret>
                    {t('HOMEPAGE_SUPPORT_SECTION_MORE_WAYS_TO_CONNECT')}
                  </Link>
                </TextContent>
              </div>
              {useDesktopLayout && <div className={styles.trustworthyIntroRight}>{deskBananasImage}</div>}
            </div>
          </ContentLayout>
        </SkewedBackgroundLayout>
        <ContentLayout
          allowContentOverflowSides
          className={styles.pricingSection}
          id='pricing'
          ref={plansSectionRef}
          pageContent
        >
          <ContentLayout contentClassName={styles.pricingSectionContent}>
            <div className={styles.pancakes}>
              <NextImage height='308' src='/img/pancakes@2x.png' width='280' />
            </div>
            <TextContent className={styles.sectionIntro}>
              <Text bold className={styles.sectionSubHeader} tint='notification'>
                {t('HOMEPAGE_PRICING_SECTION_SMALL_HEADER')}
              </Text>
              <h2 className={styles.sectionHeader}>{t('HOMEPAGE_PRICING_SECTION_HEADER')}</h2>
              <Text>{t('HOMEPAGE_PRICING_SECTION_LINE_1')}</Text>
              <Notice extendSides>
                <strong>{t('HOMEPAGE_PRICING_SECTION_NOTICE')}</strong>
              </Notice>
              <Text>{t('HOMEPAGE_PRICING_SECTION_LINE_2')}</Text>
            </TextContent>
          </ContentLayout>
          {/* <ContentLayout pageContent>
            <TextContent className={styles.annualPricingToggleSection}>
              <div className={styles.annualPricingToggleContainer}>
                <Text bold className={styles.annualPricingLabel}>
                  {t('HOMEPAGE_PRICING_SECTION_SHOW_ANNUAL_PRICING')}
                </Text>
                <Toggle
                  className={styles.annualPricingToggle}
                  checked={showAnnualPricing}
                  onChange={() => setShowAnnualPricing(!showAnnualPricing)}
                />
              </div>
            </TextContent>
            <Carousel
              className={styles.pricingCarouselContainer}
              extendSides={useMobileLayout}
              ref={pricingCarouselRef}
              showSlideIndicators={useMobileLayout}
              alignSlideIndicators='left'
              sidePadding={useDesktopLayout ? 0 : 30}
              slideSideMargin={useMobileLayout ? 5 : 15}
              slideWidth={useMobileLayout ? defaultContentWidth : 440}
            >
              <Slide className={styles.pricingSlide}>
                <FreeSubscription buttonTint='default' className={styles.pricingCard} />
              </Slide>
              <Slide className={styles.pricingSlide}>
                <BrandSubscription
                  buttonTint='creation'
                  buttonTitle={t('ACCOUNT_ONBOARDING_CREATE_CARDLAYOUT_4_BUTTON')}
                  className={`${styles.pricingCard} ${styles.priority}`}
                  showAnnualPricing={showAnnualPricing}
                  tooltipMessage={showAnnualPricing ? t('HOMEPAGE_PRICING_SECTION_SAVE_OVER_50') : null}
                  tooltipTint='creation'
                />
              </Slide>
              <Slide className={styles.pricingSlide}>
                <EnterpriseSubscription
                  className={styles.pricingCard}
                  buttonLink='/solutions/contact'
                  buttonTitle={t('CONTACT_US')}
                  showAnnualPricing={showAnnualPricing}
                />
              </Slide>
            </Carousel>
            {useMobileLayout && (
              <TextContent>
                <Text bold size='xs'>
                  {t('HOMEPAGE_PRICING_SECTION_SWIPE_FOR_OTHER_PLANS')}
                </Text>
              </TextContent>
            )}
            <TextContent>
              <Text bold>{t('HOMEPAGE_PRICING_SECTION_STILL_NOT_SURE')}</Text>
              <Button
                block
                className={`intercom ${styles.pricingConversationButton}`}
                icon={ICONS.MESSAGES.name}
                tint='navigation'
              >
                {t('START_CONVERSATION_BUTTON')}
              </Button>
            </TextContent>
            <PotentialEarningsSection />
          </ContentLayout> */}
        </ContentLayout>
      </div>
      <VideoPlayerPopup
        dismiss={closeVideo}
        id='landingPageVideo'
        playFullscreen={useMobileLayout}
        ref={videoPlayerRef}
        style={{ display: renderVideoPopup ? 'block' : 'none' }}
      >
        <div id='landingPageVideo' style={{ padding: '42.55% 0 0 0', position: 'relative', width: '100%' }}>
          <iframe
            src='https://player.vimeo.com/video/663780070?h=f43bb560b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479'
            frameBorder='0'
            allow='autoplay; fullscreen; picture-in-picture'
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
            title='OK: Imagine'
          />
        </div>
        {/* eslint-disable-next-line */}
        <script src='https://player.vimeo.com/api/player.js' />
      </VideoPlayerPopup>
      {renderFeaturesPopup && <FeaturesPopup dismiss={() => setRenderFeaturesPopup(false)} />}
      <FooterFineprint>
        <Text bold size='xs'>
          {t('HOMEPAGE_FOOTER_FINEPRINT_HEADER')}
        </Text>
        <Footnotes>
          <Footnote className={styles.footnote}>{t('HOMEPAGE_FOOTER_FOOTNOTE_1')}</Footnote>
        </Footnotes>
        <Footnotes numberingStyle='alphabetic'>
          <Footnote>{t('HOMEPAGE_FOOTER_FOOTNOTE_A')}</Footnote>
          <Footnote>{t('HOMEPAGE_FOOTER_FOOTNOTE_B')}</Footnote>
        </Footnotes>
      </FooterFineprint>
    </>
  );
}

Homepage.getLayout = (page) => (
  <MainLayout contentTopPadding={false} disableBackgroundAtTop menuStyleWithoutBackground='dark'>
    {page}
  </MainLayout>
);

export default Homepage;
