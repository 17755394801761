/* eslint-disable quotes */

import NextImage from 'next/legacy/image';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import TextContent from 'OK/components/layouts/content/text';
import Separator from 'OK/components/separator';
import Slider from 'OK/components/slider';
import Text from 'OK/components/text';
import { formatNumber } from 'OK/util/formatting';
import useI18n from 'OK/util/hooks/useI18n';

export default function PotentialEarningsSection() {
  /* Variables */

  const useDesktopLayout = useSelector((state) => state.app.useDesktopLayout);
  const { t, tHTML } = useI18n();

  /* State */

  const [licensesNeeded, setLicensesNeeded] = useState(1);
  const [potentialEarnings, setPotentialEarnings] = useState(0);
  const [profitMargin, setProfitMargin] = useState(10);
  const [turnover, setTurnover] = useState(1000000);

  /* Methods */

  const calculatePotentialEarnings = (currentTurnover, currentProfitMargin, numberOfLicenses) => {
    const potentialProfitPercent = Math.max(Math.min(50 - currentProfitMargin, 25), 0) / 100;
    const missedEarnings =
      potentialProfitPercent > 0
        ? potentialProfitPercent * currentTurnover - numberOfLicenses * LICENSE_COST_USD * 12
        : 0;
    setPotentialEarnings(Math.max(missedEarnings, 0));
  };

  /* Effects */

  // Calculate potential earnings
  useEffect(() => {
    calculatePotentialEarnings(turnover, profitMargin, licensesNeeded);
  }, [licensesNeeded, profitMargin, turnover]);

  /* Render */

  return (
    <>
      <TextContent className={styles.goodChoicesSection}>
        <Separator style={{ marginBottom: 50 }} type='section' />
        <h3>{t('HOMEPAGE_PRICING_SECTION_GOOD_CHOICES_PAY_OFF')}</h3>
        <div className={styles.splitLayout}>
          <Text className={styles.column}>{tHTML('HOMEPAGE_PRICING_SECTION_GOOD_CHOICES_LINE_1')}</Text>
          <Text className={styles.column}>{tHTML('HOMEPAGE_PRICING_SECTION_GOOD_CHOICES_LINE_2')}</Text>
        </div>
      </TextContent>
      <div className={styles.splitLayout}>
        <div className={`${styles.column} ${styles.profitExplanation}`}>
          <TextContent>
            {useDesktopLayout && <Separator style={{ marginTop: 0 }} />}
            <Text bold className={styles.potentialEarningsHeader}>
              {t('HOMEPAGE_PRICING_SECTION_POTENTIAL_EARNINGS')}
            </Text>
            {potentialEarnings > 0 && (
              <>
                <Text bold className={styles.potentialEarningsValue} tint={potentialEarnings > 0 ? 'creation' : null}>
                  {formatNumber(potentialEarnings)}
                </Text>
                <div className={styles.potentialEarningsCurrency}>
                  <Text bold size='xs'>
                    {t('HOMEPAGE_PRICING_SECTION_POTENTIAL_EARNINGS_CURRENCY')}
                  </Text>
                  <Text size='xs'>{t('HOMEPAGE_PRICING_SECTION_POTENTIAL_EARNINGS_FREQUENCY')}</Text>
                </div>
                <Text bold size='xs'>
                  {tHTML('HOMEPAGE_PRICING_SECTION_POTENTIAL_EARNINGS_DISCLAIMER')}
                </Text>
              </>
            )}
            <Button
              block
              className={`intercom ${styles.calculatorContactUsButton}`}
              icon={ICONS.MESSAGES.name}
              tint='navigation'
            >
              {t('START_CONVERSATION_BUTTON')}
            </Button>
            {potentialEarnings <= 0 && (
              <Text bold size='xs'>
                {t('HOMEPAGE_PRICING_SECTION_POTENTIAL_EARNINGS_CUSTOM')}
              </Text>
            )}
          </TextContent>
        </div>
        <CardLayout className={`${styles.column} ${styles.profitCalculator}`} fixedWidth={false}>
          <Text bold>{t('HOMEPAGE_PRICING_SECTION_CURRENT_TURNOVER')}</Text>
          <Slider
            className={styles.turnoverSlider}
            onChange={(newValue) => setTurnover(newValue)}
            persistValueTooltip
            max={100000000}
            min={100000}
            showValueInTooltip
            step={10000}
            tooltipValueRender={(value) => <>{formatNumber(value)}&nbsp;USD</>}
            type='range'
            value={turnover}
          />
          <Text bold>{t('HOMEPAGE_PRICING_SECTION_CURRENT_PROFIT')}</Text>
          <Slider
            className={styles.profitSlider}
            onChange={(newValue) => setProfitMargin(newValue)}
            persistValueTooltip
            max={100}
            min={0}
            showValueInTooltip
            step={1}
            tooltipValueRender={(value) => <>{value}&nbsp;%</>}
            type='range'
            value={profitMargin}
          />
          <div className={styles.licensesSection}>
            <Text bold className={styles.licensesSectionHeader}>
              {t('HOMEPAGE_PRICING_SECTION_LICENSES_NEEDED')}
            </Text>
            <Text>{t('HOMEPAGE_PRICING_SECTION_LICENSES_NEEDED_DESCRIPTION')}</Text>
            <Slider
              className={styles.licensesSlider}
              onChange={(newValue) => setLicensesNeeded(newValue)}
              persistValueTooltip
              max={50}
              min={1}
              showValueInTooltip
              step={1}
              tooltipValueRender={(value) => (
                <>
                  {value}&nbsp;{value > 1 ? t('LICENSE_UNIT_PLURAL') : t('LICENSE_UNIT_SINGULAR')}
                </>
              )}
              type='range'
              value={licensesNeeded}
            />
          </div>
          <div className={styles.zen}>
            <NextImage alt='' height='123' src='/img/zen@2x.png' width='113' />
          </div>
        </CardLayout>
      </div>
    </>
  );
}

/* Helper variables */

const LICENSE_COST_USD = 495;
