import { createPortal } from 'react-dom';

import useBrowserRenderOnly from 'OK/util/hooks/useBrowserRenderOnly';

export default function FooterFineprint(props) {
  const { children } = props;
  const renderForBrowser = useBrowserRenderOnly();

  if (!renderForBrowser) {
    return null;
  }

  return createPortal(children, document.getElementById('footerFineprintPortal'));
}
