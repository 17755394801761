import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import Homepage from 'OK/modules/homepage';

export default Homepage;

export async function getStaticProps({ locale }) {
  const i18nProps = await serverSideTranslations(locale, ['common']);
  return {
    props: {
      ...i18nProps,
    },
  };
}
