import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import { ICONS } from 'OK/components/icon';
import Input from 'OK/components/input';
import { LinkNoRedux } from 'OK/components/link';
import { Popup, PopupButtonsGroup, PopupContent, PopupCloseButton } from 'OK/components/popup';
import Text from 'OK/components/text';
import { downloadSolutionsBriefPDF } from 'OK/networking/sales';
import useI18n from 'OK/util/hooks/useI18n';

export default function SolutionsBriefDownloadPopup(props) {
  const { dismiss } = props;
  const { t, tHTML } = useI18n();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onChangeEmail = useCallback((e) => {
    setEmail(e.target.value);
  }, []);

  const submit = useCallback(() => {
    if (!email) {
      setEmailError('Please enter your email address.');
      return;
    }

    setError(null);
    setEmailError(null);
    setLoading(true);
    downloadSolutionsBriefPDF(email)
      .then((response) => {
        if (response.success) {
          setSubmitted(true);
        } else {
          setError(t('ERROR_GENERIC'));
        }
      })
      .catch(() => {
        setError(t('ERROR_GENERIC'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [email, t]);

  return (
    <Popup dismiss={dismiss}>
      <PopupContent className={styles.popup}>
        <div className={styles.header}>
          <h3>{t('SOLUTIONS_BRIEF_POPUP_QUICK_VIEW')}</h3>
          <PopupCloseButton linkStyle>{t('CLOSE')}</PopupCloseButton>
        </div>
        <img alt='PDF preview' className={styles.pdfPreview} src='/img/solutions_brief_preview.png' />
        <div className={`${styles.textContainer} ${styles.previewFade}`}>
          {!submitted ? (
            <>
              <h3>{t('SOLUTIONS_BRIEF_POPUP_GET_FULL_ACCESS')}</h3>
              <Text>{t('SOLUTIONS_BRIEF_POPUP_INSTRUCTIONS')}</Text>
              <Text className={styles.inputLabel} bold>
                {t('FIELD_LABEL_YOUR_EMAIL_ADDRESS')}
              </Text>
              <Input
                className={styles.input}
                onChange={onChangeEmail}
                placeholder={t('FIELD_PLACEHOLDER_ENTER_YOUR_EMAIL_ADDRESS')}
                value={email}
              />
              {emailError && (
                <Text className={styles.error} size='sm' tint='alert'>
                  {emailError}
                </Text>
              )}
              <Button
                className={styles.button}
                disabled={loading}
                icon={ICONS.DOWNLOAD.name}
                loading={loading}
                onClick={submit}
                tint='navigation'
              >
                {t('SOLUTIONS_BRIEF_POPUP_DOWNLOAD_BUTTON')}
              </Button>
              {error && (
                <Text className={styles.error} size='sm' tint='alert'>
                  {error}
                </Text>
              )}
              <Text size='xs'>
                {tHTML('SOLUTIONS_BRIEF_POPUP_FINEPRINT', {
                  data: {
                    privacyPolicyLink: (
                      <LinkNoRedux href='/solutions/terms'>{t('PRIVACY_POLICY').toLowerCase()}</LinkNoRedux>
                    ),
                  },
                })}
              </Text>
            </>
          ) : (
            <Text>{t('SOLUTIONS_BRIEF_POPUP_SUCCESS_MESSAGE')}</Text>
          )}
        </div>
      </PopupContent>
      <PopupButtonsGroup>
        <PopupCloseButton tint='navigation'>{t('CLOSE')}</PopupCloseButton>
      </PopupButtonsGroup>
    </Popup>
  );
}

SolutionsBriefDownloadPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
};
