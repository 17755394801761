import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import CardLayout from 'OK/components/layouts/card';
import Text from 'OK/components/text';

export default function FeatureCard(props) {
  const { className, description, imageUrl, paid = false, title, ...otherProps } = props;

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div className={classNames} {...otherProps}>
      {paid && <div className={styles.paidTag}>Paid</div>}
      <div className={styles.mediaContainer}>
        <div className={styles.media} style={{ backgroundImage: `url(${imageUrl})` }} />
      </div>
      <CardLayout className={styles.infoContainer} fixedWidth={false}>
        <Text bold className={styles.title}>
          {title}
        </Text>
        <Text className={styles.description} size='sm'>
          {description}
        </Text>
      </CardLayout>
    </div>
  );
}

FeatureCard.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  paid: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
