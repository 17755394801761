import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useRef, useState } from 'react';

import styles from './styles.module.scss';

const letterDelayMs = 15;

/**
 * @typedef {object} RollingTextCarouselProps
 * @prop {string} className The class for the container element.
 * @prop {number} [interval=3000] The duration (in ms) to display a word before cycling to the next word.
 * @prop {string[]} words The words to cycle through.
 */

/**
 * A component that cycles through an array of words. Each word will animate in from the bottom and out towards the top.
 *
 * @type {React.FC<RollingTextCarouselProps>}
 */
const RollingTextCarousel = forwardRef((props, forwardedRef) => {
  /* Variables */

  const { className, interval = 3000, words, ...otherProps } = props;

  // State

  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [elementHeight, setElementHeight] = useState(24);
  const [elementWidth, setElementWidth] = useState(undefined);
  const [mounted, setMounted] = useState(false);

  // Refs

  const innerRef = useRef();
  const placeholderWordsRef = useRef();
  const ref = forwardedRef || innerRef;

  /* Methods */

  const generateLettersMarkup = useCallback(
    (word, index) => {
      const letters = [];
      for (let x = 0; x < word.length; x++) {
        const letter = word[x];
        const encodedLetter = letter.trim() === '' ? '\xa0' : letter;
        letters.push(
          <span className={styles.letter} key={`${letter}-${x}`} style={{ transitionDelay: `${letterDelayMs * x}ms` }}>
            {encodedLetter}
          </span>
        );
      }

      const isFirstWord = index === 0;
      const isLastWord = index === words.length - 1;

      let wordClassNames = styles.word;
      if (index === currentWordIndex) {
        // Current word
        wordClassNames += ` ${styles.current}`;
      } else if (index === currentWordIndex + 1 || (isFirstWord && currentWordIndex === words.length - 1)) {
        // Next word
        wordClassNames += ` ${styles.next}`;
      } else if (index === currentWordIndex - 1 || (isLastWord && currentWordIndex === 0)) {
        // Previous word
        wordClassNames += ` ${styles.previous}`;
      }

      return (
        <div className={wordClassNames} key={word}>
          {letters}
        </div>
      );
    },
    [currentWordIndex, words.length]
  );

  // Set height of container according to line height
  const updateElementHeight = useCallback(() => {
    const { lineHeight } = getComputedStyle(ref.current);
    setElementHeight(parseInt(lineHeight, 10));
  }, [ref]);

  // Set width of container according to the longest word in the list
  const updateElementWidth = useCallback(() => {
    const widths = [];
    placeholderWordsRef.current.childNodes.forEach((placeholderWord) => {
      widths.push(placeholderWord.clientWidth);
    });
    const maxWidth = Math.max(...widths);
    setElementWidth(maxWidth);
  }, []);

  const updateElementSize = useCallback(() => {
    updateElementHeight();
    updateElementWidth();
  }, [updateElementHeight, updateElementWidth]);

  /* Effects */

  // Track mounted state
  useEffect(() => {
    setMounted(true);
  }, []);

  // Change current word every interval.
  useEffect(() => {
    if (!mounted) {
      return;
    }

    const changeWordInterval = setInterval(() => {
      setCurrentWordIndex((currentIndex) => {
        if (currentIndex < words.length - 1) {
          // Go to next word
          return currentIndex + 1;
        }

        // Go to first word
        return 0;
      });
    }, interval);

    return function cleanup() {
      clearInterval(changeWordInterval);
    };
  }, [interval, mounted, words.length]);

  // Set size of container
  // Previously only ran when first mounted and on window resizing. Changed to run on each render because of delay
  // between font loading and the document.fonts.ready Promise resolving.
  useEffect(() => {
    if (!mounted) {
      return;
    }
    updateElementSize();
  });

  /* Render */

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <div
      className={classNames}
      ref={ref}
      style={mounted ? { height: elementHeight, width: elementWidth } : null}
      {...otherProps}
    >
      {mounted ? (
        <>
          {words.map(generateLettersMarkup)}
          <div ref={placeholderWordsRef}>
            {words.map((word) => {
              return (
                <div className={styles.placeholderWord} key={word} style={{ opacity: 0 }}>
                  {word}
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div>{words[0]}</div>
      )}
    </div>
  );
});

RollingTextCarousel.propTypes = {
  className: PropTypes.string,
  interval: PropTypes.number,
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default RollingTextCarousel;
