import NextImage from 'next/legacy/image';
import PropTypes from 'prop-types';
import { useCallback, useContext } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import Text from 'OK/components/text';
import ThemeContext from 'OK/util/context/theme';
import useI18n from 'OK/util/hooks/useI18n';

export default function ArticleCard(props) {
  /* Variables */

  const {
    addMediaSizer = true,
    authorImageUrl,
    authorJobTitle,
    authorName,
    className,
    href,
    imageUrl,
    readingTime,
    title,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const theme = useContext(ThemeContext);

  /* Event handlers */

  const openArticle = useCallback(
    (e) => {
      if (!window.getSelection().isCollapsed) {
        // Cancel click because the user is highlighting text.
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      window.open(href, '_blank');
    },
    [href]
  );

  /* Render */

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <CardLayout
      className={classNames}
      innerClassName={styles.containerInner}
      onClick={openArticle}
      padded={false}
      {...otherProps}
    >
      <div className={styles.badge}>
        {t('ARTICLE_CARD_READ_ARTICLE')}
        {readingTime && (
          <span style={{ fontWeight: 'normal' }}>
            &nbsp;({readingTime} {t('MINUTE_UNIT_SHORT')})
          </span>
        )}{' '}
        <Icon className={styles.externalLinkIcon} height={12} name={ICONS.EXTERNAL_LINK.name} width={12} />
      </div>
      <div className={styles.media}>
        <NextImage
          alt='Article image.'
          className={styles.backgroundImage}
          layout='fill'
          objectFit='cover'
          src={imageUrl ? imageUrl : `/img/empty_media_${theme.name}.svg`}
        />
        <div className={styles.mediaOverlay} />
      </div>
      {addMediaSizer && <div className={styles.mediaSizer} />}
      <div className={styles.topLayer}>
        <Text className={styles.title} lineLimit={2}>
          {title}
        </Text>
        <div className={styles.authorContainer}>
          <div>
            <Text bold className={styles.authorName}>
              {authorName}
            </Text>
            <Text className={styles.authorJobTitle}>{authorJobTitle}</Text>
          </div>
          <NextImage
            alt='Article author'
            className={styles.authorImage}
            height={60}
            src={authorImageUrl ? authorImageUrl : `/img/empty_media_${theme.name}.svg`}
            width={60}
          />
        </div>
      </div>
    </CardLayout>
  );
}

ArticleCard.propTypes = {
  addMediaSizer: PropTypes.bool,
  authorImageUrl: PropTypes.string.isRequired,
  authorJobTitle: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  readingTime: PropTypes.number,
  title: PropTypes.string.isRequired,
};
