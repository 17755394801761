import PropTypes from 'prop-types';
import { useRef } from 'react';

import styles from './styles.module.scss';

import { Carousel, Slide } from 'OK/components/carousel';
import FeatureCard from 'OK/components/feature/card';
import { Popup, PopupButtonsGroup, PopupCloseButton, PopupContent } from 'OK/components/popup';
import ScrollToNextSectionButton from 'OK/components/scrollToNextSectionButton';
import Separator from 'OK/components/separator';
import Text from 'OK/components/text';

const FEATURES = {
  QUALITY: [
    {
      title: 'Create labels',
      description: 'Generate print-ready QR code labels to place on products and packaging.',
      imageUrl: '/img/webinar_img.jpg',
    },
    {
      title: 'Use org wide',
      description: 'Require coworkers to use MFA to verify key actions by both SMS and email.',
      imageUrl: '/img/webinar_img.jpg',
      paid: true,
    },
    {
      title: 'Create labels 2',
      description: 'Generate print-ready QR code labels to place on products and packaging.',
      imageUrl: '/img/webinar_img.jpg',
    },
    {
      title: 'Use org wide 2',
      description: 'Require coworkers to use MFA to verify key actions by both SMS and email.',
      imageUrl: '/img/webinar_img.jpg',
    },
  ],
  DOCUMENTATION: [
    {
      title: 'Create labels',
      description: 'Generate print-ready QR code labels to place on products and packaging.',
      imageUrl: '/img/webinar_img.jpg',
      paid: true,
    },
    {
      title: 'Use org wide',
      description: 'Require coworkers to use MFA to verify key actions by both SMS and email.',
      imageUrl: '/img/webinar_img.jpg',
    },
    {
      title: 'Create labels 2',
      description: 'Generate print-ready QR code labels to place on products and packaging.',
      imageUrl: '/img/webinar_img.jpg',
    },
    {
      title: 'Use org wide 2',
      description: 'Require coworkers to use MFA to verify key actions by both SMS and email.',
      imageUrl: '/img/webinar_img.jpg',
    },
  ],
};

export default function FeaturesPopup(props) {
  /* Variables */

  const { dismiss } = props;

  /* Refs */

  const documentationSectionRef = useRef();
  const popupRef = useRef();
  const qualitySectionRef = useRef();

  /* Render */

  const renderFeatures = (features) => {
    return (
      <>
        <Carousel className={styles.featuresCarousel} fadeOutSides innerClassName={styles.featuresCarouselInner}>
          {features.map((feature) => {
            const { title, description, imageUrl, paid } = feature;
            return (
              <Slide className={styles.featureSlide} key={title}>
                <FeatureCard
                  className={styles.featureCard}
                  title={title}
                  description={description}
                  imageUrl={imageUrl}
                  paid={paid}
                />
              </Slide>
            );
          })}
        </Carousel>
        <Text bold className={styles.swipeInstructions} size='xs'>
          Swipe to see other features
        </Text>
      </>
    );
  };

  return (
    <Popup dismiss={dismiss} ref={popupRef}>
      <PopupContent>
        <div className={styles.header}>
          <h3>Features list</h3>
          <PopupCloseButton linkStyle tint='navigation'>
            Close
          </PopupCloseButton>
        </div>
        <div ref={qualitySectionRef}>
          <h4>Quality and compliance</h4>
          {renderFeatures(FEATURES.QUALITY)}
        </div>
        <Separator type='section' />
        <div ref={documentationSectionRef}>
          <h4>Documentation</h4>
          {renderFeatures(FEATURES.DOCUMENTATION)}
        </div>
      </PopupContent>
      <PopupButtonsGroup>
        <div className={styles.popupButtonsContainer}>
          <PopupCloseButton className={styles.floatingCloseButton} tint='navigation'>
            Close
          </PopupCloseButton>
          <ScrollToNextSectionButton
            className={styles.nextSectionButton}
            containerRef={popupRef}
            sectionRefs={[qualitySectionRef, documentationSectionRef]}
          />
        </div>
      </PopupButtonsGroup>
    </Popup>
  );
}

FeaturesPopup.propTypes = {
  dismiss: PropTypes.func.isRequired,
};
