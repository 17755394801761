import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

import CardLayout from 'OK/components/layouts/card';
import Separator from 'OK/components/separator';
import Text from 'OK/components/text';

export default function TestimonialCard(props) {
  const { authorImageUrl, authorName, logoImageUrl, organisationName, quote, ...otherProps } = props;

  return (
    <CardLayout innerClassName={styles.containerInner} fixedWidth={false} {...otherProps}>
      <div className={styles.logoContainer}>
        <img alt='Logo' className={styles.logo} src={logoImageUrl} />
      </div>
      <p className={styles.parentheses}>&quot;</p>
      <div className={styles.quote}>{quote}</div>
      <Separator className={styles.separator} type='section' />
      <div className={styles.authorContainer}>
        <div>
          <Text bold className={styles.authorName}>
            {authorName}
          </Text>
          <Text className={styles.organisationName}>{organisationName}</Text>
        </div>
        <NextImage alt='Author' height='61' src={authorImageUrl} width='61' />
      </div>
    </CardLayout>
  );
}

TestimonialCard.propTypes = {
  authorImageUrl: PropTypes.string.isRequired,
  authorName: PropTypes.string.isRequired,
  logoImageUrl: PropTypes.string.isRequired,
  organisationName: PropTypes.string.isRequired,
  quote: PropTypes.node.isRequired,
};
