import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useCallback } from 'react';

import styles from './styles.module.scss';

import CardLayout from 'OK/components/layouts/card';
import Text from 'OK/components/text';
import useI18n from 'OK/util/hooks/useI18n';

export const EventType = {
  DEMO: 'DEMO',
  PRESS_RELEASE: 'PRESS_RELEASE',
  WEBINAR: 'WEBINAR',
};

export default function EventCard(props) {
  /* Variables */

  const {
    badge,
    className,
    dateString,
    eventType,
    href,
    imageUrl,
    onClick: onClickProp,
    squareMedia = true,
    title,
    ...otherProps
  } = props;
  const { t } = useI18n();
  const hasClickHandler = typeof onClickProp === 'function';
  const linkMode = typeof href === 'string';
  const clickable = hasClickHandler || linkMode;

  /* Methods */

  /* Event handlers */

  const onClick = useCallback(
    (e) => {
      if (clickable) {
        if (!window.getSelection().isCollapsed) {
          // Cancel click because the user is highlighting text.
          e.preventDefault();
          e.stopPropagation();
          return false;
        }

        if (hasClickHandler) {
          onClickProp(e);
        } else {
          window.open(href, '_blank');
        }
      }
    },
    [clickable, hasClickHandler, href, onClickProp]
  );

  /* Render */

  // Classes
  let classNames = styles.container;
  if (clickable) {
    classNames += ` ${styles.clickable}`;
  }
  if (className) {
    classNames += ` ${className}`;
  }

  // Event type
  let eventTypeLabel;
  switch (eventType) {
    case EventType.DEMO:
      eventTypeLabel = t('FREE_DEMO');
      break;
    case EventType.PRESS_RELEASE:
      eventTypeLabel = t('PRESS_RELEASE');
      break;
    case EventType.WEBINAR:
      eventTypeLabel = t('FREE_WEBINAR');
      break;
    default:
      eventTypeLabel = t('EVENT');
      break;
  }

  return (
    <div className={classNames} onClick={onClick} {...otherProps}>
      {badge && <div className={styles.badge}>{badge}</div>}
      <div className={`${styles.mediaContainer} ${squareMedia ? styles.square : ''}`}>
        <NextImage alt='Event image.' className={styles.media} layout='fill' objectFit='cover' src={imageUrl} />
        <div className={styles.mediaOverlay} />
      </div>
      <CardLayout className={styles.detailsContainer} fixedWidth={false} padded={false}>
        <Text className={styles.title} lineLimit={2}>
          {title}
        </Text>
        <Text bold className={styles.eventType}>
          {eventTypeLabel}
        </Text>
        <Text className={styles.date}>{dateString}</Text>
      </CardLayout>
    </div>
  );
}

EventCard.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  className: PropTypes.string,
  dateString: PropTypes.string.isRequired,
  eventType: PropTypes.oneOf([EventType.DEMO, EventType.PRESS_RELEASE, EventType.WEBINAR]).isRequired,
  href: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  squareMedia: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
