import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';

import styles from './styles.module.scss';

import Button from 'OK/components/button';
import Icon, { ICONS } from 'OK/components/icon';
import { baseTheme } from 'OK/styles/theme';

/**
 * @typedef {object} ReactRef
 * @prop {Node} current
 */

/**
 * @typedef {object} ScrollToNextSectionButtonProps
 * @prop {string} [className] The class for the button.
 * @prop {ReactRef} containerRef Ref for the container section to scroll.
 * @prop {ReactRef[]} sectionRefs Refs for the sections.
 */

/**
 * A button fixed to the bottom right which scrolls to the next section when clicked. The button will automatically
 * hide itself when the last section is reached.
 *
 * @type {React.FC<ScrollToNextSectionButtonProps>}
 */
export default function ScrollToNextSectionButton(props) {
  /* Variables */

  const { className, containerRef, sectionRefs } = props;

  /* State */

  const [sectionIntersectionRatios, setSectionIntersectionRatios] = useState([0, 0]);

  let indexOfMostVisibleSection;
  sectionIntersectionRatios.reduce((prev, curr, idx) => {
    if (curr > prev) {
      indexOfMostVisibleSection = idx;
      return curr;
    }

    return prev;
  }, -1);

  /* Event handlers */

  // Scroll to next section when clicked
  const onClick = useCallback(() => {
    const sectionRefToScrollTo = sectionRefs[indexOfMostVisibleSection + 1];
    const scrollTop = sectionRefToScrollTo.current.offsetTop;
    containerRef.current.scrollTo({ top: scrollTop - baseTheme.spacing.contentMarginLg, behavior: 'smooth' });
  }, [containerRef, indexOfMostVisibleSection, sectionRefs]);

  /* Effects */

  // Track most visible section
  useEffect(() => {
    const elements = sectionRefs.map((sectionRef) => sectionRef.current);
    const observer = new IntersectionObserver(
      (entries) => {
        const newIntersectionRatios = elements.map(() => null);
        entries.forEach((entry) => {
          const intersectionRatio = entry.intersectionRatio;
          const indexOfMatchingSection = elements.findIndex((section) => entry.target === section);
          if (indexOfMatchingSection > -1) {
            newIntersectionRatios[indexOfMatchingSection] = intersectionRatio;
          }
        });
        setSectionIntersectionRatios((currentRatios) => {
          return newIntersectionRatios.map((ratio, index) => {
            return ratio ?? currentRatios[index];
          });
        });
      },
      { threshold: [0, 0.25, 0.5, 0.75, 1] }
    );

    elements.forEach((section) => {
      observer.observe(section);
    });

    return function () {
      elements.forEach((section) => {
        observer.unobserve(section);
      });
    };
  }, [sectionRefs]);

  /* Render */

  const showNextSectionButton = indexOfMostVisibleSection < sectionIntersectionRatios.length - 1;

  let classNames = styles.button;
  if (!showNextSectionButton) {
    classNames += ` ${styles.hidden}`;
  }
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <Button className={classNames} invert onClick={onClick} tint='navigation'>
      <Icon className={styles.icon} name={ICONS.CARET.name} />
    </Button>
  );
}

ScrollToNextSectionButton.propTypes = {
  className: PropTypes.string,
  containerRef: PropTypes.any.isRequired,
  sectionRefs: PropTypes.array.isRequired,
};
