import NextImage from "next/legacy/image";
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import styles from './styles.module.scss';

import Icon, { ICONS } from 'OK/components/icon';
import CardLayout from 'OK/components/layouts/card';
import Text from 'OK/components/text';
import SolutionsBriefDownloadPopup from 'OK/modules/popups/solutionsBriefDownload';
import useI18n from 'OK/util/hooks/useI18n';

export default function MarketingPDFCard(props) {
  const { className, ...otherProps } = props;
  const { t } = useI18n();

  const [renderSolutionsBriefPopup, setRenderSolutionsBriefPopup] = useState(false);

  const openPopup = useCallback(() => {
    setRenderSolutionsBriefPopup(true);
  }, []);

  const closePopup = useCallback(() => {
    setRenderSolutionsBriefPopup(false);
  }, []);

  let classNames = styles.container;
  if (className) {
    classNames += ` ${className}`;
  }

  return (
    <>
      <CardLayout
        className={classNames}
        fixedWidth={false}
        innerClassName={styles.innerContainer}
        onClick={openPopup}
        {...otherProps}
      >
        <div className={styles.badge}>
          {t('DOWNLOAD')} <Icon inline name={ICONS.DOWNLOAD.name} style={{ marginLeft: 5 }} />
        </div>
        <div className={styles.media}>
          <NextImage
            alt='A person working in a factory.'
            className={styles.backgroundImage}
            height='1334'
            layout='fill'
            objectFit='cover'
            src='/img/solutions_deck_image@2x.jpg'
            width='832'
          />
          <div className={styles.mediaOverlay} />
        </div>
        <div className={styles.topLayer}>
          <h4 className={styles.title}>{t('MARKETING_PDF_CARD_PDF_TITLE')}</h4>
          <Text bold style={{ margin: 0 }}>
            {t('MARKETING_PDF_CARD_FREE_SOLUTIONS_DECK')}
          </Text>
          <Text style={{ marginBottom: 12 }}>{t('MARKETING_PDF_CARD_PRESS_TO_DOWNLOAD')}</Text>
        </div>
      </CardLayout>
      {renderSolutionsBriefPopup && <SolutionsBriefDownloadPopup dismiss={closePopup} />}
    </>
  );
}

MarketingPDFCard.propTypes = {
  className: PropTypes.string,
};
